<template>
  <v-col
    cols="12"
    md="12"
  >
    <!-- <v-row
      no-gutters
      class="pb-4"
    >
      <v-col cols="auto">
        <v-btn
          class="mr-2"
          color="secondary"
        >
          Все
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="actions-bar"
      >
        <v-btn
          color="green"
          @click="createDialog"
        >
          Добавить продукт
        </v-btn>
      </v-col>
    </v-row> -->

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Продукты</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество {{ productsList.length }}</h5>
        </div>
      </template>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="productsList"
          :loading="loading"
        >
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              color="red"
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template> -->
        </v-data-table>
      </v-card-text>
    </base-material-card>

    <!-- <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> Продукт </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.name"
                    label="Название"
                    :error-messages="errors.name"
                    @input="errors.name = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.supplier_id"
                    :items="suppliersList"
                    item-text="name"
                    item-value="id"
                    label="Поставщик"
                    :error-messages="errors.supplier_id"
                    @change="errors.supplier_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.category_id"
                    :items="productCategoriesList"
                    item-text="name"
                    item-value="id"
                    label="Категория"
                    :error-messages="errors.category_id"
                    @change="errors.category_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.amount"
                    label="Стоимость"
                    :error-messages="errors.amount"
                    @input="errors.amount = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.weight"
                    label="Bec"
                    :error-messages="errors.weight"
                    @input="errors.weight = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.total"
                    label="Стоимость"
                    :error-messages="errors.total"
                    @input="errors.total = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editProduct"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createProduct"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteProduct"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-col>
</template>

<script>
import ProductsService from '@/app/Services/ProductsService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
// import GetSuppliers from '@/app/Helpers/mixins/essences/getSuppliers'

export default {
  name: 'ProductsDashboard',

  mixins: [
    TableDialogMixin,
    // GetSuppliers
  ],

  data() {
    return {
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Имя',
          value: 'name',
        },
        {
          sortable: true,
          text: 'Стоимость',
          value: 'price',
        },
        // {
        //   sortable: false,
        //   text: 'Действия',
        //   value: 'actions',
        // },
      ],
      productsList: [],
      dialogData: {
        id: null,
        name: '',
        supplier_id: null,
        category_id: null,
        amount: null,
        weight: null,
        total: null,
      },
    }
  },
  computed: {
    productCategoriesList() {
      return this.$store.state.references.productCategories
    },
  },

  async created() {
    this.loading = true

    await this.getProducts()
    //await this.getSuppliers()

    this.loading = false
  },

  methods: {
    async getProducts() {
      const response = await ProductsService.getProducts()

      if (response.status) {
        this.productsList = response.data.data

        this.$store.dispatch('products/setProductsData', response.data.data)
      }
    },

    async createProduct() {
      if (this.$refs.form.validate()) {
        const response = await ProductsService.createProduct(this.dialogData)

        if (response.status) {
          await this.getProducts()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async editProduct() {
      if (this.$refs.form.validate()) {
        const response = await ProductsService.editProduct(this.selectedId, this.dialogData)

        if (response.status) {
          await this.getProducts()
          this.dialog = false
        } else {
          this.errors = response.error
        }
      }
    },

    async deleteProduct() {
      const response = await ProductsService.deleteProduct(this.selectedId)

      if (response.status) {
        await this.getProducts()
        this.dialogDelete = false
      } else {
        this.errors = response.error
      }
    },
  },
}
</script>
